import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import * as ImagePicker from 'expo-image-picker'
import store, { RootState } from '../../../redux/Store'
import { addPhoto, clearPhotos } from '../../../redux/SmartClaimSlice'

import photo from '../../VehicleFront.png';

function VehiclePhotos(props) {
	const [errordisplay, setErrorDisplay] = useState('none')
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

	let images = []
  const canContinue = () => images.length > 1
  const canUpload = () => {
    images.length < 2
      ? pickImage()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  const canTake = () => {
    images.length < 2
      ? takePhoto()
      : alert('You have already uploaded 2 photos. Select Manage Photos to view or remove uploaded photos.')
  }
  const pickImage = async () => {
    const result : any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.uri))
		{
      // check file type! (validation method)
	  		let theimage = null;

			if (Platform.OS != "web")
			{
				theimage = 'data:image/jpeg;base64,' + result.base64;
				//console.log(result.base64);
			}
			else
			{
				theimage = result.uri;
				//console.log(result.uri);
			}
			setErrorDisplay('none')
			props.onPhotoSelected(theimage)
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  const takePhoto = async () => {
    const result : any = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      aspect: [4, 3],
      quality: 1
    })
    if (!result.cancelled) {
		//console.log(result);
		if (isValidImage(result.uri))
		{
      // check file type! (validation method)
		console.log(Platform.OS);
			if (Platform.OS == "android")
			{
				store.dispatch(addPhoto('data:image/jpeg;base64,' + result.base64))
				//console.log(result.base64);
			}
			else
			{
				store.dispatch(addPhoto(result.uri))
				//console.log(result.uri);
			}
			setErrorDisplay('none')
		}
		else {
	  setErrorDisplay('flex')
		}
    }
  }
  
  function isValidImage(dataURI){
	  return true;
	  var retVal = false;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
	if (mimeString == 'image/jpeg' || mimeString == 'image/png')
	{
		retVal = true;
	}
	return retVal;
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Now we need two photos of your vehicle. One from the front showing the vehicle and registration, and one of the dash pod confirming the current mileage.</Para>
	  <Para>First Photo – Front of Vehicle.</Para>
	  <Para>Select the NEXT button below to access your camera and take the photo.</Para>
        <VerticalSpace height={15}/>
		<View style={styles.detailsBoxWrapper}>
			<View style={styles.detailsBox}>
				<Image style={styles.photoWrapper} source={photo}></Image>
				<View style={styles.detailsBoxText}>
					<Para color={'#fff'}>Please see example above.</Para>
				</View>
			</View>
		</View>
		<Para bold={false} color={'red'} display={errordisplay} centerAlign={true} fontSize={12}>Invalid file type, please upload a jpg or png file</Para>
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
			<DefaultButton greenbg={true} text="Next" onClick={canUpload}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default VehiclePhotos;



const styles = StyleSheet.create({
  detailsBoxWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  detailsBox: {
	color: '#fff',
	width: 314,
  },
  detailsBoxText: {
	backgroundColor: '#4fb0e5',
	color: '#fff',
	padding: 10,
  },
  photoWrapper: {
	  width: 314,
	  height: 196,
  },
})

