import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from './components/Bottom';
import DefaultButton from './components/DefaultButton';
import Heading from './components/Heading';
import Para from './components/Para';
import styles from './../constants/Styles'
import VerticalSpace from './components/VerticalSpace'


function Welcome(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <Heading>
		<View style={styles.container}>
	  Welcome to Manheim SureCheck
		</View>
	</Heading>
    <View style={styles.container}>
	  <VerticalSpace height={45}/>
      <Para>Please select one of the following options to start a new Report or update an existing Report.</Para>
        <VerticalSpace height={15}/>

      <Bottom>
        <DefaultButton text="New Report" onClick={props.onNewRequest}/>
        <VerticalSpace height={55}/>
        <DefaultButton text="Update Report" onClick={props.onUpdateRequest}/>
        <VerticalSpace height={15}/>
        <DefaultButton text="Get Inspection Report" onClick={props.onGetInspectionReport}/>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default Welcome;
