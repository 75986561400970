import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import SelectedButton from '../../components/SelectedButton';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import ButtonList from '../../components/ButtonList'
import Components from './components.json'


function RequestComponent(props) {
	const [selectedComponent, updateSelectedComponent] = useState('')
	const selectedCategory = Components.find(item => item.category === props.category);
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function handleChosenType(value) {
    updateSelectedComponent(value);
  }

  function canContinue () {
    return selectedComponent !== ''
  }
  
  if (selectedCategory.components.length == 0)
  {
	  props.onSelectedComponent('')
  }
  else
  {
  
  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Which specific component of the...</Para>
	  <ConditionalButton text={props.category} condition={() => function nofunc(){return false;}} onClick={() => function nofunc(){}}/>
      <Para>...has the fault?</Para>
        <VerticalSpace height={15}/>
			<ButtonList>
			{selectedCategory.components.map(item => <View style={Styles.optionsButton}><SelectedButton selected={selectedComponent == item} text={item} onClick={() => handleChosenType(item)}/></View>)}
			  
			</ButtonList>
      <Bottom>
        <VerticalSpace height={45}/>
		<View>
			<ConditionalButton greenbg={true} text="Submit" condition={canContinue} onClick={() => props.onSelectedComponent(selectedComponent)}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
  }
}

export default RequestComponent;


