import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text } from 'react-native'
import Colors from '../../constants/Colors'

type SelectedButtonProps = {
  text: string,
  onClick: (event: GestureResponderEvent) => void,
  selected: boolean
}

const SelectedButton: FunctionComponent<SelectedButtonProps> = (props: SelectedButtonProps) => {
  if (props.selected == true) {
    return (
	<Pressable style={styles.selectedButton} onPress={props.onClick}>
      <Text style={styles.selectedButtonText}>{props.text}</Text>
    </Pressable>
	)
  } else {
	return (
    <Pressable style={styles.defaultButton} onPress={props.onClick}>
      <Text style={styles.buttonText}>{props.text}</Text>
    </Pressable>
	)
  }
}

const styles = StyleSheet.create({
  buttonText: {
    color: Colors.primaryPurple,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  selectedButtonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  defaultButton: {
    backgroundColor: '#ffffff',
	borderWidth: 1,
	borderColor: Colors.primaryPurple,
    borderRadius: 18,
    padding: 10
  },
  selectedButton: {
    backgroundColor: '#312e49',
    borderRadius: 18,
    padding: 10
  }
})

export default SelectedButton
