import { View, StyleSheet, Text, TextInput, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

function VehicleLocation(props) {
	const [description, updateDescription] = useState(props.additional_info)
	const [vehicleLocation, updateVehicleLocation] = useState(props.vehicle_address)
	const [vehiclePostcode, updateVehiclePostcode] = useState(props.vehicle_postcode)
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function canContinue () {
    return vehicleLocation !== '' && vehiclePostcode !== ''
  }
  
  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Please use this section below for any information you would like to provide regarding the fault, the more detailed the information, the quicker it is for the SureCheck Team to review your request. This can include information such as what the fault is, when it occurred, any Diagnosis Codes or Parts/Labour costs for repair.</Para>
	  <TextInput multiline={true} numberOfLines={4} style={styles.multilineTextInput} onChangeText={updateDescription} value={description}>
	  </TextInput>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Please confirm the Location of the vehicle." value={vehicleLocation} onChange={updateVehicleLocation} placeholder="Address Line 1 *"/>
        <VerticalSpace height={15}/>
		<TextInput value={vehiclePostcode} placeholder={"Postcode *"} style={styles.textInput} onChangeText={updateVehiclePostcode}/>
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
		<ConditionalButton greenbg={true} condition={canContinue} text="Next" onClick={() => props.onSubmit(description, vehicleLocation, vehiclePostcode)}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default VehicleLocation;



const styles = StyleSheet.create({
  multilineTextInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 140,
    padding: 5,
	paddingBottom: 15,
	textAlignVertical: 'top'
  },
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 35,
    paddingHorizontal: 4
  },
})

