import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import SelectedButton from '../../components/SelectedButton';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import ButtonList from '../../components/ButtonList'

function ExistingRequestCategory(props) {
	const [selectedCategory, updateSelectedCategory] = useState(props.categories[props.current_category_index].category_name)
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);
	
  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Is the highlighted fault below correct?</Para>
        <VerticalSpace height={15}/>
			<ButtonList>
			{props.categories.map(item => <View style={Styles.optionsButton}><SelectedButton selected={selectedCategory == item.category_name} text={item.category_name} onClick={() => handleChosenType(item.category)}/></View>)}
			</ButtonList>
      <Bottom>
        <VerticalSpace height={45}/>
		<View>
			<DefaultButton greenbg={true} text="Yes" onClick={() => props.onExistingSelectedCategoryYes(selectedCategory)}/>
			<VerticalSpace height={15}/>
			<DefaultButton text="No" onClick={() => props.onExistingSelectedCategoryNo(selectedCategory)}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default ExistingRequestCategory;


