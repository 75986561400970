import React from 'react'
import { Text } from 'react-native'

type HeadingProps = {
  children: string
}

const SmallHeading = ({ children }: HeadingProps) => {
  return <Text style={{ color: '#0d4489', backgroundColor: '#ffffff', fontSize: 28, paddingBottom: 15, paddingTop: 15 }}>{children}</Text>
}

export default SmallHeading
