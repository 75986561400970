import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function YourDetails(props) {
	const [firstName, updateFirstName] = useState(props.first_name)
	const [surname, updateSurname] = useState(props.last_name)
	const [companyName, updateCompanyName] = useState(props.company_name)
	const [email, updateEmail] = useState(props.email)
	const [phone, updatePhone] = useState(props.phone)
	const emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function canContinue () {
    return firstName !== '' && surname !== '' && phone !== '' && companyName !== '' && email !== '' && emailValid()
  }
  
  function  emailValid() {
    return (emailreg.test(email));
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Your Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Please enter your personal details</Para>
	  <Para>* indicates a required field</Para>
        <VerticalSpace height={15}/>
		<LabeledTextField label="First Name*" value={firstName} onChange={updateFirstName}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Surname*" value={surname} onChange={updateSurname}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Company Name*" value={companyName} onChange={updateCompanyName}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Email*" value={email} onChange={updateEmail}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Phone*" value={phone} onChange={updatePhone}/>
      <Bottom>
        <VerticalSpace height={45}/>
        <ConditionalButton greenbg={true} text="Next" condition={canContinue} onClick={() => props.onSubmit(firstName, surname, companyName, email, phone)}/>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default YourDetails;


