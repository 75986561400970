import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text, Linking, View } from 'react-native'
import Colors from '../../constants/Colors'

type DefaultButtonProps = {

}

const HelpButton: FunctionComponent<DefaultButtonProps> = (props: DefaultButtonProps) => {
	const helpLink = 'https://www.manheim.co.uk/campaigns/surecheck-faqs'
  return (
  <View style={styles.buttonWrapper} >
	<Pressable style={styles.defaultButton} onPress={() => Linking.openURL(`${helpLink}`)}>
	  <Text style={styles.buttonText}>Need Help ?</Text>
	</Pressable>
  </View>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    color: Colors.primaryPurple,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  defaultButton: {
    backgroundColor: Colors.white,
	borderColor: Colors.primaryPurple,
	borderWidth: 1,
    borderRadius: 18,
    padding: 10,
	width: 200,
  },
  buttonWrapper: {
	flex: 1,
    justifyContent: "center",
    alignItems: "center",	  
  }
})

export default HelpButton
