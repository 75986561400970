import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../components/Bottom';
import ConditionalButton from '../components/ConditionalButton';
import LabeledTextField from '../components/LabeledTextField'
import SmallHeading from '../components/SmallHeading';
import Para from '../components/Para';
import styles from '../../constants/Styles'
import VerticalSpace from '../components/VerticalSpace'


function SearchVehicle(props) {
	const [registrationNumber, updateRegistrationNumber] = useState('')
	const [searching, updateSearching] = useState(false)
	const [buttonText, updateButtonText] = useState('Next')
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function canContinue () {
    return registrationNumber !== '' && searching == false
  }

  function onSearch () {
	updateButtonText('Please wait ...')
	updateSearching(true)
    props.onSearch(registrationNumber)
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={styles.container}>
	  <SmallHeading>
	    Vehicle Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Please enter your vehicle registration or VIN number.</Para>
        <VerticalSpace height={15}/>
		<LabeledTextField regstyle={true} placeholder="REG/VIN NUMBER" label="Reg/Vin number" value={registrationNumber} onChange={updateRegistrationNumber}/>
      <Bottom>
        <VerticalSpace height={45}/>
        <ConditionalButton greenbg={true} text={buttonText} condition={canContinue} onClick={onSearch}/>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default SearchVehicle;
