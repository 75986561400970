import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function VehicleNotFound(props) {
	const [fullName, updateFullName] = useState('')
	const [regNumber, updateRegNumber] = useState(props.vrn)
	const [make, updateMake] = useState('')
	const [model, updateModel] = useState('')
	const [description, updateDescription] = useState('')
	const [mileage, updateMileage] = useState('')
	const [location, updateLocation] = useState('')
	const [email, updateEmail] = useState('')
	const [phone, updatePhone] = useState('')
	const [submitting, updateSubmitting] = useState(false)
	const [buttonText, updateButtonText] = useState('Submit')
	const emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function canContinue () {
    return fullName !== '' && regNumber !== '' && phone !== '' && email !== '' && emailValid() && make !== '' && model !== '' && description !== '' && mileage !== '' && location !== '' && submitting == false
  }
  
  function emailValid() {
    return (emailreg.test(email));
  }

  function onSubmit() {
	  updateSubmitting(true)
	  updateButtonText('Please Wait ...');
    props.onSubmit(fullName, phone, email, regNumber, make, model, description, mileage, location)
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
        <VerticalSpace height={15}/>
		<View style={styles.detailsBox}>
			<View style={styles.detailsHeaderRow}>
				<Text style={styles.detailsHeaderRowTitle}>Sorry</Text>
			</View>
			<View style={styles.detailsRow}>
				<Para>We're unable to find a record of this vehicle.</Para>
				<Para>However, if you can complete the details below we will call you to continue your Request.</Para>
			</View>
		</View>
	  <SmallHeading>
	    Your Details
	  </SmallHeading>
		<Para color={"#ff0000"}>* required fields</Para>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Your Full Name *" value={fullName} onChange={updateFullName}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Your Phone Number *" value={phone} onChange={updatePhone}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Email Address *" value={email} onChange={updateEmail}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Reg Number *" value={regNumber} onChange={updateRegNumber}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Make *" value={make} onChange={updateMake}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Model *" value={model} onChange={updateModel}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Fault Description *" value={description} onChange={updateDescription}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Current Mileage *" value={mileage} onChange={updateMileage}/>
        <VerticalSpace height={15}/>
		<LabeledTextField label="Location *" value={location} onChange={updateLocation}/>
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
			<ConditionalButton greenbg={true} text={buttonText} condition={canContinue} onClick={onSubmit}/>
			<VerticalSpace height={15}/>
			<DefaultButton text="Go Back" onClick={() => props.goBack()}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default VehicleNotFound;


const styles = StyleSheet.create({
  detailsBox: {
    borderWidth: 1,
	borderColor: '#333',
    fontSize: 16,
  },
  detailsHeaderRow: {
    padding: 8,
    backgroundColor: '#cc0000',
  },
  detailsRow: {
    padding: 8,
    backgroundColor: '#ffffff',
  },
  detailsHeaderRowTitle: {
    fontWeight: 'bold',
	color: '#ffffff',
  },
  detailsRowValue: {
    marginLeft: 10,
  },
  buttonList: {
	  
  },
})

