import each from 'async/each';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as ImageManipulator from 'expo-image-manipulator';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'
import axios from 'axios'

const delay = ms => new Promise(res => setTimeout(res, ms));

class API {
  constructor() {
    this.urlBase = 'https://api.autoprotect-app.co.uk/';
    this.apiBase = this.urlBase + 'api/';
    this.username = 'consumerapp';
    this.password = '13e77e4fb7c2346075fa1533420d1d2c';
    this.standardTimeout = 30 * 1000;

    // OAuth token
    this.accessToken = '';
    this.accessTokenExpiresIn = '';
    this.accessTokenExpiryTime = '';
    // Customer session token
    this.sessionToken = '123';
    // AP Staff Id
	const theApp = this;
	AsyncStorage.getItem('submittedBy', (err, result) => {
          theApp.submittedBy = result || '';
    });
    

    // Get the OAuth token on start-up
    this.getAccessToken();

    this.manheimPhoto = this.manheimPhoto.bind(this);
  }
  
  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async getAccessToken () {
    const api = this

    const data = this.xWwwFormUrlEncoded({
      grant_type: 'password',
      username: this.username,
      password: this.password
    })

    const response = await fetch(
      this.urlBase + 'token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: data
      }
    )
    const json = await response.json()
    api.accessToken = json.access_token
	api.accessTokenExpiresIn = json.expires_in
	api.accessTokenExpiryTime = new Date(Date.now() + (json.expires_in * 60))
  }



  async timeoutPromise() {
	  await delay(this.standardTimeout);
  }
  
  async policies(vrn, done, notFound, fail) {
    let api = this;
    var url = this.apiBase +
      'policies/?registrationNumber=' + encodeURIComponent(vrn) +
      '&version=9' +
      '&src=' + Platform.OS;


    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        api.timeoutPromise()
      ])
	  if (response == null) {
		  fail()
	  }
      else if (response.status == 401) {
        fail()
	  }
      else if (response.status != 200) {
		  console.log('not found')
        notFound()
      } else {
        const json = await response.json()
        //api.sessionToken = json.session_token

        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }
  
  async rehydrateSession(token) {
	  this.sessionToken = token
  }

  async userSession(data, done, fail) {
    let api = this;
    let url = this.apiBase +
      'usersession?session_token=' + encodeURIComponent(this.sessionToken) +
      '&registrationNumber=' + encodeURIComponent(data.registrationNumber) +
      '&postcode=' + encodeURIComponent(data.postcode) +
      '&policyNumber=' + encodeURIComponent(data.policyNumber) +
      '&firstName=' + encodeURIComponent(data.firstName) +
      '&lastName=' + encodeURIComponent(data.surname) +
      '&mobileNumber=' + encodeURIComponent(data.mobileNumber) +
      '&email=' + encodeURIComponent(data.email) +
      '&mileage=' + encodeURIComponent(data.mileage) +
      '&src=' + Platform.OS;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      api.sessionToken = json.session_token
	  if (response.status == 200)
	  {
		done(json)
	  }
	  else
	  {
		  fail('failed')
	  }
    } catch (e) {
      fail(e)
    }
  }

  async getPolicyDocument(policyId, done, fail) {
    let url = this.apiBase +
      'PolicyPackDocument/?policyNumber=' + encodeURIComponent(policyId);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  };

  async manheimClaim(policy, repairer, first_name, last_name, company_name, email, phone, mileage, additional_info, vehicle_address, vehicle_postcode, vrn, policy_id, policy_sold_date, policy_dealer_name, policy_vehicle_model, policy_vehicle_id, previous_claim_id, done, fail) {
    let url = this.apiBase +
      'manheimclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policy_number=' + encodeURIComponent(policy.policyNo) +
      '&repairer=' + encodeURIComponent(repairer) +
      '&first_name=' + encodeURIComponent(first_name) +
      '&last_name=' + encodeURIComponent(last_name) +
      '&company_name=' + encodeURIComponent(company_name) +
      '&email=' + encodeURIComponent(email) +
      '&phone=' + encodeURIComponent(phone) +
      '&mileage=' + encodeURIComponent(mileage) +
      '&additional_info=' + encodeURIComponent(additional_info) +
      '&vehicle_address=' + encodeURIComponent(vehicle_address) +
      '&vehicle_postcode=' + encodeURIComponent(vehicle_postcode) +
      '&vrn=' + encodeURIComponent(vrn) +
      '&policy_id=' + encodeURIComponent(policy_id) +
      '&policy_sold_date=' + encodeURIComponent(policy_sold_date) +
      '&policy_dealer_name=' + encodeURIComponent(policy_dealer_name) +
      '&policy_vehicle_model=' + encodeURIComponent(policy_vehicle_model) +
      '&policy_vehicle_id=' + encodeURIComponent(policy_vehicle_id) +
      '&previous_claim_id=' + encodeURIComponent(previous_claim_id);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async manheimCategory(manheimClaimId, category, component, imageData, done, fail, progress) {
    let url = this.apiBase +
      'manheimclaimcategory/?manheimClaimId=' + encodeURIComponent(manheimClaimId) +
      '&category=' + encodeURIComponent(category) +
      '&component=' + encodeURIComponent(component);
	  
	try {
    let fd = new FormData();
	
	if (imageData != "" && imageData != null)
	{
		const manipResult = await ImageManipulator.manipulateAsync(
		  imageData,
		  [{ resize: { width: 640 } }],
		  { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
		);
		let blob = '';
		

		if (Platform.OS != "web")
		{
			blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);
			//fd.append('uploadedImage', blob, 'photo.png');
			fd.append('uploadedImage', {
				uri: manipResult.uri,
				type: 'image/png',
				name: 'photo.png',
				data: blob,
			  });
		}
		else
		{
			blob = this.dataURItoBlob(manipResult.uri);
			fd.append('uploadedImage', blob, 'photo.png');
		}
	}


	axios.request({
		 method: "post", 
		url: url, 
		data: fd,
		headers: this.accessHeadersFormData(),
		timeout: 5 * 60 * 1000,
		onUploadProgress: (p) => {
		  progress("3", p.loaded, p.total)
		}
	})
	  .then(function (response) {
		  done(response)
	  })
	  .catch(function (error) {
		// handle error
		console.log(error)
		fail(error)
	  })
	  .finally(function () {
		// always executed
	  });

    } catch (e) {
		console.log(e)
      fail(e)
    }
  }

  async ManheimUnknownVehicle(full_name, phone, email, reg_number, make, model, description, mileage, location, done, fail) {
    let url = this.apiBase +
      'manheimclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&full_name=' + encodeURIComponent(full_name) +
      '&phone=' + encodeURIComponent(phone) +
      '&email=' + encodeURIComponent(email) +
      '&reg_number=' + encodeURIComponent(reg_number) +
      '&make=' + encodeURIComponent(make) +
      '&model=' + encodeURIComponent(model) +
      '&description=' + encodeURIComponent(description) +
      '&mileage=' + encodeURIComponent(mileage) +
      '&location=' + encodeURIComponent(location)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  manheimPhoto(claimId, imageData, photoType, done, fail, uploadProgress) {
    this.uploadPhoto('manheimclaimphoto/', 'manheimClaimId', claimId, imageData, photoType, done, fail, uploadProgress);
  }

  async uploadPhoto(endpoint, claimIdParam, claimId, imageData, photoType, done, fail, progress) {
    let url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photoType);
	  
	try {
	const manipResult = await ImageManipulator.manipulateAsync(
      imageData,
	  [{ resize: { width: 640 } }],
      { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
    );
    let blob = '';
	
    let fd = new FormData();

	if (Platform.OS != "web")
	{
		blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);
		//fd.append('uploadedImage', blob, 'photo.png');
		fd.append('uploadedImage', {
			uri: manipResult.uri,
			type: 'image/png',
			name: 'photo.png',
			data: blob,
		  });
	}
	else
	{
		blob = this.dataURItoBlob(manipResult.uri);
		fd.append('uploadedImage', blob, 'photo.png');
	}	

      /*const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders(),
			body: fd,
			timeout: 5 * 60 * 1000,
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])*/

	axios.request({
		 method: "post", 
		url: url, 
		data: fd,
		headers: this.accessHeadersFormData(),
		timeout: 5 * 60 * 1000,
		onUploadProgress: (p) => {
		  progress(photoType, p.loaded, p.total)
		}
	})
	  .then(function (response) {
		  done(response)
	  })
	  .catch(function (error) {
		// handle error
		console.log(error)
		fail(error)
	  })
	  .finally(function () {
		// always executed
	  });

    } catch (e) {
		console.log(e)
      fail(e)
    }

  };

  // Uploads photos in parallel. Each photo object in the array should have the
  // following structure:
  // { data: 'uri-image-data', type: 'photo type' }
  // photoFunc should be one of the api photo uploading functions (alloyPhoto,
  // tyrePhoto, or smartPhoto).
  uploadPhotos(photoFunc, claimId, photos, done, fail, uploadProgress) {
    each(
      photos,
      function submit(photo, callback) {
        photoFunc(
          claimId,
          photo.data,
          photo.type,
          function done() {
            callback();
          },
          function fail(xhr) {
            callback(xhr);
          },
		  uploadProgress
        )
      },
      function callback(err) {
        if (err) {
          fail(err);
        } else {
          done();
        }
      }
    );
  }

  accessHeaders() {
	  let dtnow = new Date()
	  if (this.accessTokenExpiryTime < dtnow)
	  {
		  this.getAccessToken()
	  }

    return {
      "Authorization": "Bearer " + this.accessToken
    };
  }

  accessHeadersFormData() {
	  let dtnow = new Date()
	  if (this.accessTokenExpiryTime < dtnow)
	  {
		  this.getAccessToken()
	  }

    return {
      "Authorization": "Bearer " + this.accessToken,
	  "Content-Type": "multipart/form-data"
    };
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
}

export default API;
