import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text } from 'react-native'
import Colors from '../../constants/Colors'

type DefaultButtonProps = {
  text: string,
  onClick: (event: GestureResponderEvent) => void,
  newStyle: any,
  greenbg: bool
}

const DefaultButton: FunctionComponent<DefaultButtonProps> = (props: DefaultButtonProps) => {
	if (props.greenbg == true)
	{
	  return (
		<Pressable style={styles.greenButton} onPress={props.onClick}>
		  <Text style={styles.greenButtonText}>{props.text}</Text>
		</Pressable>
		)
	}
	else if (props.newStyle == null)
	{
	  return (
		<Pressable style={styles.defaultButton} onPress={props.onClick}>
		  <Text style={styles.buttonText}>{props.text}</Text>
		</Pressable>
	  )
	}
	else
	{
	  return (
		<Pressable style={props.newStyle} onPress={props.onClick}>
		  <Text style={styles.buttonText}>{props.text}</Text>
		</Pressable>
	  )
	}
		
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  greenButtonText: {
    color: '#555',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  defaultButton: {
    backgroundColor: Colors.primaryPurple,
    borderRadius: 18,
    padding: 10
  },
  greenButton: {
    backgroundColor: Colors.green,
    borderRadius: 18,
    padding: 10
  }
})

export default DefaultButton
