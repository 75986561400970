import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import SelectedButton from '../../components/SelectedButton';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import ButtonList from '../../components/ButtonList'
import Components from './components.json'


function RequestCategory(props) {
	const [selectedCategory, updateSelectedCategory] = useState('')
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);
	
  function handleChosenType(value) {
    updateSelectedCategory(value);
  }

  function canContinue () {
    return selectedCategory !== ''
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Please select one of the following categories</Para>
        <VerticalSpace height={15}/>
			<ButtonList>
			{Components.map(item => <View style={Styles.optionsButton}><SelectedButton selected={selectedCategory == item.category} text={item.category} onClick={() => handleChosenType(item.category)}/></View>)}
			</ButtonList>
      <Bottom>
        <VerticalSpace height={45}/>
		<View>
			<ConditionalButton greenbg={true} text="Submit" condition={canContinue} onClick={() => props.onSelectedCategory(selectedCategory)}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default RequestCategory;


