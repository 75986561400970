import { View, StyleSheet, Text, TextInput, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import * as Progress from 'react-native-progress';

function Submitting(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
		<View style={styles.centerdiv}>
		  <SmallHeading>
		  Sending
		  </SmallHeading>
		  <VerticalSpace height={45}/>
		  <Progress.Bar progress={props.photoUploadProgress} width={200} />
		</View>
    </View>
  </Animated.View>
  );
}

export default Submitting;



const styles = StyleSheet.create({
  centerdiv: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  }
})

