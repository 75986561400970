import React, { Component } from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native'
//import IdleTimer from 'react-idle-timer';
//import API from './API';
import Styles from './../constants/Styles'
//import faq from './faq.png';
import home from './home.svg';
import logo from './logo.png';
import Welcome from './Welcome';
import NewRequest from './apps/NewRequest/NewRequest';
import InspectionReport from './apps/InspectionReport/InspectionReport';
import HelpButton from './components/HelpButton';
import VerticalSpace from './components/VerticalSpace'

import NavigationBar from './components/NavigationBar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

class MainApp extends Component {
  constructor() {
    super();
	
    this.state = {
      stage: 'welcome',
	  clearState: false,
	  existingRequest: false,
    };

    this.onNewRequest = this.onNewRequest.bind(this);
    this.onExistingRequest = this.onExistingRequest.bind(this);
    this.onGetInspectionReport = this.onGetInspectionReport.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  onNewRequest() {
	  this.setState({stage: 'new_request'});
  }

  onExistingRequest() {
	  this.setState({stage: 'new_request', existingRequest: true});
  }

  onGetInspectionReport() {
	  this.setState({stage: 'inspection_report', existingRequest: true});
  }

  clearData() {
	  this.setState({stage: 'welcome'});
  }

  render() {
    return (
		<KeyboardAwareScrollView style={Styles.outerContainer}>
			  <View style={Styles.AppHeader}>
				<NavigationBar goHome={() => this.startAgain()} loggedin={true} logout={() => this.logout()} clearData={() => this.clearData()}></NavigationBar>
			  </View>
			  <View style={Styles.AppBody}>
				{this.screen()}
			  </View>
			  <View style={Styles.container}>
		          <VerticalSpace height={55}/>
				  <HelpButton />
			  </View>

		</KeyboardAwareScrollView>
    );
  }

  screen() {
	  if (this.state.stage == 'new_request')
	  {
		return <NewRequest existingRequest={this.state.existingRequest} />
	  }
	  else if (this.state.stage == 'inspection_report')
	  {
		return <InspectionReport goBack={() => this.setState({stage: 'welcome'})} />
	  }
	  else
	  {
		return <Welcome onNewRequest={() => this.onNewRequest()} onUpdateRequest={() => this.onExistingRequest()} onGetInspectionReport={() => this.onGetInspectionReport()}/>;
	  }
  }
}

export default MainApp;
