import React from 'react'
import { Text } from 'react-native'

type HeadingProps = {
  children: string
}

const Heading = ({ children }: HeadingProps) => {
  return <Text style={{ color: '#ffffff', backgroundColor: '#227cb7', fontSize: 28, paddingBottom: 15, paddingTop: 15 }}>{children}</Text>
}

export default Heading
