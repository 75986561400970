import React, { Component } from 'react';
import SearchVehicle from '../../Vehicle/SearchVehicle';
import YourVehicle from './YourVehicle';
import VehicleNotFound from './VehicleNotFound';
import YourDetails from './YourDetails';
import Repairer from './Repairer';
import RequestCategory from './RequestCategory';
import ExistingRequestCategory from './ExistingRequestCategory';
import RequestComponent from './RequestComponent';
import ComponentPhoto from './ComponentPhoto';
import VehiclePhotos from './VehiclePhotos';
import PhotoCheck from './PhotoCheck';
import SpeedometerPhoto from './SpeedometerPhoto';
import VehicleLocation from './VehicleLocation';
import Confirm from './Confirm';
import Submitting from './Submitting';
import SubmitComplete from './SubmitComplete';
import NotFoundSubmitComplete from './NotFoundSubmitComplete';
import ExistingRequest from './ExistingRequest';
import UploadError from './UploadError';
import API from '../../API';

class category_element {
	category_name: '';
	component: '';
	componentPhoto: null;

	constructor(category_name_in, component_in, componentPhoto_in) {
     this.category_name=category_name_in; 
     this.component=component_in; 
     this.componentPhoto=componentPhoto_in; 
   }   
}

class NewRequest extends Component {
  constructor(props) {
    super();

    this.state = {
      stage: 'searchVehicle',
	  categories: [],
	  current_category: '',
	  current_component: '',
	  current_componentPhoto: null,
	  vehiclePhoto: null,
	  speedoPhoto: null,
	  photoProgress: 0,
	  vehiclePhotoProgress: 0,
	  speedoPhotoProgress: 0,
	  vrn: '',
	  policy: null,
	  repairer: '',
	  first_name: '',
	  last_name: '',
	  company_name: '',
	  email: '',
	  phone: '',
	  mileage: '',
	  additional_info: '',
	  vehicle_address: '',
	  vehicle_postcode: '',
	  update_request: props.existingRequest,
	  existing_category_index: 0,
	  policy_id: '',
	  policy_sold_date: '',
	  policy_dealer_name: '',
	  policy_vehicle_model: '',
	  policy_vehicle_id: '',
	  previous_claim_id: 0,
    };
	this.onVehiclePhotoSelected = this.onVehiclePhotoSelected.bind(this);
	this.onSpeedoPhotoSelected = this.onSpeedoPhotoSelected.bind(this);
	this.onComponentPhotoSelected = this.onComponentPhotoSelected.bind(this);
	this.onSubmitClaim = this.onSubmitClaim.bind(this);
	this.onSubmitSearchVehicle = this.onSubmitSearchVehicle.bind(this);
	this.onNotFoundSubmit = this.onNotFoundSubmit.bind(this);
	this.onCategorySelected = this.onCategorySelected.bind(this);
	this.onComponentPhotoChecked = this.onComponentPhotoChecked.bind(this);
	this.onComponentSelected = this.onComponentSelected.bind(this);
	this.onExistingSelectedCategoryYesSelected = this.onExistingSelectedCategoryYesSelected.bind(this);
	this.onExistingSelectedCategoryNoSelected = this.onExistingSelectedCategoryNoSelected.bind(this);

    this.apiFail = this.apiFail.bind(this);
    this.apiDone = this.apiDone.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);

  };
  
  uploadProgress(photoType, loaded, total)
  {
	  let pc = loaded / total
	  if (photoType == "1")
	  {
		  let theprogress = this.state.speedoPhotoProgress + pc;
		  this.setState({vehiclePhotoProgress: pc, photoProgress: theprogress})
	  }
	  else if (photoType == "0")
	  {
		  let theprogress = this.state.vehiclePhotoProgress + pc;
		  this.setState({speedoPhotoProgress: pc, photoProgress: theprogress})
	  }
	  else
	  {
		  let theprogress = this.state.photoProgress + pc;
		  this.setState({photoProgress: theprogress})
	  }
  }

  onVehiclePhotoSelected (photo) {
    this.setState({stage: 'photoCheck', vehiclePhoto: photo})
  }

  onSpeedoPhotoSelected (photo) {
    this.setState({stage: 'speedoPhotoCheck', speedoPhoto: photo})
  }
  
  onComponentPhotoSelected (photo) {
    this.setState({stage: 'componentPhotoCheck', current_componentPhoto: photo})
  }

  onComponentSelected (component) {
	this.setState({stage: 'componentPhoto', current_component: component})
  }
  
  onExistingSelectedCategoryYesSelected (category) {
	  if (this.state.repairer == "true")
	  {
		this.setState({stage: 'component', current_category: category, current_component: '', current_componentPhoto: ''})
	  }
	  else
	  {
		  this.setState({current_category: category, current_component: '', current_componentPhoto: ''})
		  const ele = new category_element(category, '', '');
		  var local_categories = this.state.categories.slice();

		  local_categories[this.state.existing_category_index] = ele;

		  if (this.state.existing_category_index < this.state.categories.length - 1)
		  {
			  this.setState({stage: 'existingRequestCategory', categories: local_categories, existing_category_index: this.state.existing_category_index + 1})
		  }
		  else
		  {
			  this.setState({stage: 'vehicleLocation', categories: local_categories})
		  }
	  }
  }

  onExistingSelectedCategoryNoSelected (category) {
		this.setState({stage: 'category', current_category: category})
  }

  onComponentPhotoChecked () {
	  const ele = new category_element(this.state.current_category, this.state.current_component, this.state.current_componentPhoto);
      var local_categories = this.state.categories.slice();
	  
	  console.log(this.state.existing_category_index);

	  if (this.state.update_request === false || this.state.existing_category_index >= this.state.categories.length)
	  {
		  local_categories.push(ele);
		  if (this.state.vehiclePhoto == null)
		  {
			this.setState({stage: 'vehiclePhoto', categories: local_categories})
		  }
		  else
		  {
			this.setState({stage: 'vehicleLocation', categories: local_categories})
		  }
	  }
	  else
	  {
		  local_categories[this.state.existing_category_index] = ele;
		  if (this.state.existing_category_index < this.state.categories.length - 1)
		  {
			  this.setState({stage: 'existingRequestCategory', categories: local_categories, existing_category_index: this.state.existing_category_index + 1})
		  }
		  else
		  {
			  this.setState({stage: 'vehicleLocation', categories: local_categories, existing_category_index: this.state.existing_category_index + 1})
		  }
	  }

  }
  
  onCategorySelected(category) {
	  if (this.state.repairer == "true")
	  {
		this.setState({stage: 'component', current_category: category, current_component: '', current_componentPhoto: ''})
	  }
	  else
	  {
		  this.setState({current_category: category, current_component: '', current_componentPhoto: ''})
		  const ele = new category_element(category, '', '');
		  var local_categories = this.state.categories.slice();

		  local_categories.push(ele);

		  if (this.state.vehiclePhoto == null)
		  {
			this.setState({stage: 'vehiclePhoto', categories: local_categories})
		  }
		  else
		  {
			this.setState({stage: 'vehicleLocation', categories: local_categories})
		  }
	  }
  }

  onSubmitClaim () {
	  this.setState({stage: "submitting"})
	  let api = new API();
		const component = this;
	api.manheimClaim(this.state.policy, this.state.repairer, this.state.first_name, this.state.last_name, this.state.company_name, this.state.email, this.state.phone, this.state.mileage, this.state.additional_info, this.state.vehicle_address, this.state.vehicle_postcode, this.state.vrn, this.state.policy_id, this.state.policy_sold_date, this.state.policy_dealer_name, this.state.policy_vehicle_model, this.state.policy_vehicle_id, this.state.previous_claim_id, function done(result) {
		let allPhotos = [];
		if (component.state.vehiclePhoto != null)
		{
			allPhotos.push(component.state.vehiclePhoto);
		}
		if (component.state.speedoPhoto != null)
		{
			allPhotos.push(component.state.speedoPhoto);
		}
		const photos = allPhotos.map(function(p, index) { return {type: index.toString(), data: p}; });
		api.uploadPhotos(
		  api.manheimPhoto,
		  result.claim_id,
		  photos,
		  component.apiDone,
		  component.apiFail,
		  component.uploadProgress
		);
		
		component.state.categories.map(
			function(cat, index) {
				console.log(index)
				api.manheimCategory(
				  result.claim_id,
				  cat.category_name,
				  cat.component,
				  cat.componentPhoto,
				  component.apiDone,
				  component.apiFail,
				  component.uploadProgress
				);
			}
		)
		
	}, function fail(xhr) {
		console.log(xhr);
		component.setState({stage: 'uploadError'});
	});
  }
  
  apiFail(xhr) {
	this.setState({stage: 'uploadError'});
  }
  
  apiDone() {
		this.setState({stage: 'submitComplete'});
  }

  onSubmitSearchVehicle (vrn) {
	  let theApp = this;
	  let api = new API();
	  this.setState({vrn: vrn});
	  	  console.log(this.state)


	api.policies(vrn, function done(response) {
	  if (response.claims.length > 0 && theApp.state.update_request === false)
	  {
		theApp.setState({stage: 'existingRequest'});
	  }
	  if (response.claims.length == 0)
	  {
		theApp.setState({update_request: false});
		  
	  }
	  if (response.claims.length > 0 && theApp.state.update_request === true)
	  {
		theApp.setState({stage: 'yourVehicle', policy: response.policy, current_category: response.claims[0].categories[0].category,
		  current_component: response.claims[0].categories[0].component,
		  vrn: response.claims[0].vrn,
		  repairer: response.claims[0].repairer,
		  first_name: response.claims[0].first_name,
		  last_name: response.claims[0].last_name,
		  company_name: response.claims[0].company_name,
		  email: response.claims[0].email,
		  phone: response.claims[0].phone,
		  mileage: response.claims[0].mileage,
		  additional_info: response.claims[0].additional_info,
		  vehicle_address: response.claims[0].vehicle_address,
		  vehicle_postcode: response.claims[0].vehicle_postcode,
		  policy_id: response.policy.id, policy_sold_date: response.policy.SoldDate, policy_dealer_name: response.policy.DealerName, policy_vehicle_model: response.policy.Vehicle.Model, policy_vehicle_id: response.policy.Vehicle.Id, previous_claim_id: response.claims[0].id});
	  
		  var local_categories = [];
	    response.claims[0].categories.map(function(p, index) { 
			  const ele = new category_element(p.category, p.component, '');

			  local_categories.push(ele);
		});
		theApp.setState({categories: local_categories});
	  
	  }
	  else if (response.policy) {
		console.log(response.policy.id);
			theApp.setState({policy_id: response.policy.Id, policy_sold_date: response.policy.SoldDate, policy_dealer_name: response.policy.DealerName, policy_vehicle_model: response.policy.Vehicle.Model, policy_vehicle_id: response.policy.Vehicle.Id, stage: 'yourVehicle', policy: response.policy});
	  }
		console.log(response);
	}, function notFound() {
		theApp.setState({stage: 'vehicleNotFound'});
	}, function fail(xhr) {
		theApp.setState({stage: 'vehicleNotFound'});
	});

	  if (vrn === "ccc")
	  {
		this.setState({stage: 'existingRequest'});
	  }
  }
  
  onSubmitDetails(first_name, last_name, company_name, email, phone)
  {
	  if (this.state.update_request == true)
	  {
		  this.setState({stage: 'existingRequestCategory',first_name: first_name, last_name: last_name, company_name: company_name, email: email, phone: phone})
	  }
	  else
	  {
		  this.setState({stage: 'category',first_name: first_name, last_name: last_name, company_name: company_name, email: email, phone: phone})
	  }
  }
  
  onNotFoundSubmit(full_name, phone, email, reg_number, make, model, description, mileage, location) {	  
	  let api = new API();
		const component = this;
	api.ManheimUnknownVehicle(full_name, phone, email, reg_number, make, model, description, mileage, location, function done(result) {
		component.setState({stage: 'notFoundSubmitComplete'})

	}, function fail(xhr) {
		console.log(xhr);
		component.setState({stage: 'uploadError'});
	});
  }

  photoTimer() {
	  if (this.state.photoProgress > 1)
	  {
		  this.setState({stage: "submitComplete"})
	  }
	  else
	  {
		  let photoProgressLocal = this.state.photoProgress + 0.1
		this.setState({photoProgress: photoProgressLocal})
		setTimeout(() => this.photoTimer(), 500)
	  }
  }

  render() {
    if (this.state.stage === 'yourVehicle') {
      return(
        <YourVehicle policy={this.state.policy} onYourVehicleYes={() => this.setState({stage: 'repairer'})} onYourVehicleNo={() => this.setState({stage: 'vehicleNotFound'})} />
      );
    }
	else if (this.state.stage === 'vehicleNotFound') {
      return(
        <VehicleNotFound vrn={this.state.vrn} onSubmit={this.onNotFoundSubmit} />
      );
    }
	else if (this.state.stage === 'existingRequest') {
      return(
        <ExistingRequest onUpdate={() => this.setState({stage: 'yourVehicle'})} onStartNew={() => this.setState({stage: 'repairer'})} />
      );
    }
	else if (this.state.stage === 'repairer') {
		if (this.state.repairer != "")
		{
			this.setState({stage: 'yourDetails'});
		}
	  return(
		<Repairer onRepairerYes={() => this.setState({stage: 'yourDetails', repairer: 'true'})} onRepairerNo={() => this.setState({stage: 'yourDetails', repairer: 'false'})} />
	  );
    }
	else if (this.state.stage === 'yourDetails') {
      return(
        <YourDetails first_name={this.state.first_name} last_name={this.state.last_name} company_name={this.state.company_name} email={this.state.email} phone={this.state.phone} onSubmit={(first_name, last_name, company_name, email, phone) => this.onSubmitDetails(first_name, last_name, company_name, email, phone)} />
      );
    }
	else if (this.state.stage === 'category') {
      return(
        <RequestCategory onSelectedCategory={(category) => this.onCategorySelected(category)} />
      );
    }
	else if (this.state.stage === 'existingRequestCategory') {
      return(
        <ExistingRequestCategory categories={this.state.categories} current_category_index={this.state.existing_category_index} onExistingSelectedCategoryYes={(category) => this.onExistingSelectedCategoryYesSelected(category)}  onExistingSelectedCategoryNo={(category) => this.onExistingSelectedCategoryNoSelected(category)} />
      );
    }
	else if (this.state.stage === 'component') {
      return(
        <RequestComponent category={this.state.current_category} onSelectedComponent={this.onComponentSelected} />
      );
    }
	else if (this.state.stage === 'componentPhoto') {
      return(
        <ComponentPhoto onPhotoSelected={this.onComponentPhotoSelected} />
      );
    }
	else if (this.state.stage === 'componentPhotoCheck') {
      return(
        <PhotoCheck onPhotoYes={this.onComponentPhotoChecked} onPhotoNo={() => this.setState({stage: 'componentPhoto'})} photo={this.state.current_componentPhoto} />
      );
    }
	else if (this.state.stage === 'vehiclePhoto') {
      return(
        <VehiclePhotos onPhotoSelected={this.onVehiclePhotoSelected} />
      );
    }
	else if (this.state.stage === 'photoCheck') {
      return(
        <PhotoCheck onPhotoYes={() => this.setState({stage: 'speedoPhoto'})} onPhotoNo={() => this.setState({stage: 'vehiclePhoto'})} photo={this.state.vehiclePhoto} />
      );
    }
	else if (this.state.stage === 'speedoPhoto') {
      return(
        <SpeedometerPhoto onPhotoSelected={this.onSpeedoPhotoSelected} />
      );
    }
	else if (this.state.stage === 'speedoPhotoCheck') {
      return(
        <PhotoCheck mileage={true} onPhotoYes={(mileage) => this.setState({stage: 'vehicleLocation', mileage: mileage})} onPhotoNo={() => this.setState({stage: 'speedoPhoto'})} photo={this.state.speedoPhoto} />
      );
    }
	else if (this.state.stage === 'vehicleLocation') {
      return(
        <VehicleLocation additional_info={this.state.additional_info} vehicle_address={this.state.vehicle_address} vehicle_postcode={this.state.vehicle_postcode} onSubmit={(description, vehicleLocation, vehiclePostcode) => this.setState({stage: 'confirm', additional_info: description, vehicle_address: vehicleLocation, vehicle_postcode: vehiclePostcode})}  />
      );
    }
	else if (this.state.stage === 'confirm') {
      return(
        <Confirm onSubmit={() => this.onSubmitClaim()} categories={this.state.categories} onAddAnother={() => this.setState({stage: 'category'})} />
      );
    }
	else if (this.state.stage === 'submitting') {
      return(
        <Submitting photoUploadProgress={this.state.photoProgress} />
      );
    }
	else if (this.state.stage === 'submitComplete') {
      return(
        <SubmitComplete />
      );
    }
	else if (this.state.stage === 'notFoundSubmitComplete') {
      return(
        <NotFoundSubmitComplete />
      );
    }
	else if (this.state.stage === 'uploadError') {
      return(
        <UploadError />
      );
    }
	else {
      return(
        <SearchVehicle onSearch={this.onSubmitSearchVehicle}/>
      );
    }
  }
}

export default NewRequest;
