import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function ExistingRequest(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);


  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <VerticalSpace height={15}/>
	  <Para>A Request has already been started for this vehicle. Please select one of the options below to continue.</Para>
      <Bottom>
        <VerticalSpace height={45}/>
		<View>
			<DefaultButton text="Update" onClick={props.onUpdate}/>
			<VerticalSpace height={15}/>
			<DefaultButton greenbg={true} text="Start New" onClick={props.onStartNew}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default ExistingRequest;


