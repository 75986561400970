import { StyleSheet, Dimensions } from 'react-native';
import Colors from '../constants/Colors'

let paddingAmount = 35;
const windowWidth = Dimensions.get('window').width;

if (windowWidth > 400)
{
	paddingAmount = 100;
}

export default {
standardPadding: paddingAmount,
	outerContainer: {
		alignSelf: 'center',
		width: '100%',
		fontSize: 50,
	},
	bottom: {
	  marginTop: 4,
	  boxSizing: 'border-box',
	  marginBottom: 20,
	},
	defaultButton: {
	  color: '#fff',
	  backgroundColor: '#312e49',
	  fontSize: '1.5rem',
	  lineHeight: '1.875rem',
	  padding: '0.5rem 0',
	  display: 'block',
	  width: '100%',
	  border: 'none',
	  cursor: 'pointer',
	  borderRadius: '0.375rem',
	},
	optionsButton: {
		paddingBottom: 10,
	},
	optionsButton: {
		paddingBottom: 10,
	},
	AppHeader: {
	  backgroundColor: '#1d1a45',
	  color: 'white',
	  position: 'relative',
	  zIndex: 9,
	},
	AppInfo: {
	  position: 'absolute',
	  width: '2.5rem',
	  height: '1.875rem',
	  lineHeight: '1.5rem',
	  top: '1.25rem',
	  right: '2.5rem',
	},
	AppInfoImg: {
	  height: '100%',
	},
	AppHomeImg: {
	  marginLeft: '2.5rem',
	  marginTop: '1.125rem',
	  height: '2rem',
	  width: '2rem',
	  color: '#fff',
	  fill: 'currentColor',
	},
	AppLogo: {
	  position: 'absolute',
	  display: 'block',
	  height: 54,
	  width: 173,
	  marginLeft: '-3.61rem',
	  left: '50%',
	  top: 0,
	  marginTop: '1.0rem',
	},
	AppIntro: {
	  fontSize: 'large',
	},
	AppBody: {
	  boxSizing: 'border-box',
	},
	cardetailsbox: {
		border: '2px #000000 solid',
		padding: '10px',
		color: '#000000',
	},
	listButton: {
		paddingBottom: 10,
	},
	refundTableColumnLeft: {

	},
	container: {
		paddingHorizontal: paddingAmount,
	  },
  linkstyle: {
	  color: '#0000ff',
	  textDecorationLine: 'underline',
	  cursor: 'pointer',
  },
  bullet: {
	  marginLeft: 10,
	  padding: 5,
	  fontSize: 16,
  },
  textStyle: {
  }
}