import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function NoInspectionReport(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
        <VerticalSpace height={15}/>
		<View style={styles.detailsBox}>
			<View style={styles.detailsHeaderRow}>
				<Text style={styles.detailsHeaderRowTitle}>Sorry</Text>
			</View>
			<View style={styles.detailsRow}>
				<Para>We're unable to find an inspection report for this vehicle. It can take up to 24 hours following vehicle inspection for the information to be available. Please contact a member of the Manheim Auction Centre or visit www.manheim.co.uk for further details on this vehicle.</Para>
			</View>
		</View>
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
			<DefaultButton text="Go Back" onClick={() => props.goBack()}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default NoInspectionReport;


const styles = StyleSheet.create({
  detailsBox: {
    borderWidth: 1,
	borderColor: '#333',
    fontSize: 16,
  },
  detailsHeaderRow: {
    padding: 8,
    backgroundColor: '#cc0000',
  },
  detailsRow: {
    padding: 8,
    backgroundColor: '#ffffff',
  },
  detailsHeaderRowTitle: {
    fontWeight: 'bold',
	color: '#ffffff',
  },
  detailsRowValue: {
    marginLeft: 10,
  },
  buttonList: {
	  
  },
})

