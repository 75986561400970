import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ConditionalButton from '../../components/ConditionalButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

function PhotoCheck(props) {
	const [mileage, updateMileage] = useState('')
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  function canContinue () {
    return mileage !== ''
  }

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Request Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Are you happy with this photo?.</Para>
        <VerticalSpace height={15}/>
		<View style={styles.detailsBoxWrapper}>
			<View style={styles.detailsBox}>
				<Image style={styles.photoWrapper} source={props.photo}></Image>
			</View>
		</View>
		{props.mileage != null ?
			<View>
				<LabeledTextField label="Mileage*" value={mileage} onChange={updateMileage}/>
			</View>
			:
			<View></View>
		}
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
		{props.mileage != null ?
			<View>
				<ConditionalButton greenbg={true} text="Yes" condition={canContinue} onClick={() => props.onPhotoYes(mileage)}/>
			</View>
			:
			<View>
				<DefaultButton greenbg={true} text="Yes" onClick={props.onPhotoYes}/>
			</View>
		}
			<VerticalSpace height={15}/>
			<DefaultButton text="No" onClick={props.onPhotoNo}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default PhotoCheck;



const styles = StyleSheet.create({
  detailsBoxWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  detailsBox: {
	color: '#fff',
	width: 314,
  },
  detailsBoxText: {
	backgroundColor: '#4fb0e5',
	color: '#fff',
	padding: 10,
  },
  photoWrapper: {
	  width: 314,
	  height: 196,
  },
})

