import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function YourVehicle(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);


  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Your Vehicle Details
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Is this the correct model information below?</Para>
        <VerticalSpace height={15}/>
		<View style={styles.detailsBox}>
			<View style={styles.detailsRowOdd}>
				<Text style={styles.detailsRowTitle}>Reg Number</Text>
				<Text style={styles.detailsRowValue}>{props.policy.Vehicle.VRN}</Text>
			</View>
			<View style={styles.detailsRowEven}>
				<Text style={styles.detailsRowTitle}>Make / Model</Text>
				<Text style={styles.detailsRowValue}>{props.policy.Vehicle.Model}</Text>
			</View>
			<View style={styles.detailsRowOdd}>
				<Text style={styles.detailsRowTitle}>Auction Centre</Text>
				<Text style={styles.detailsRowValue}>{props.policy.DealerName}</Text>
			</View>
			<View style={styles.detailsRowEven}>
				<Text style={styles.detailsRowTitle}>Start Date</Text>
				<Text style={styles.detailsRowValue}>{new Date(props.policy.SoldDate).toDateString("dds/MM/yyyy HH:MM")}</Text>
			</View>
		</View>
      <Bottom>
        <VerticalSpace height={45}/>
		<View style={styles.buttonList}>
			<DefaultButton greenbg={true} text="Yes" onClick={props.onYourVehicleYes}/>
			<VerticalSpace height={15}/>
			<DefaultButton text="No" onClick={props.onYourVehicleNo}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default YourVehicle;


const styles = StyleSheet.create({
  detailsBox: {
    borderWidth: 1,
	borderColor: '#333',
    fontSize: 16,
    padding: 4
  },
  detailsRowOdd: {
    backgroundColor: '#e9e9e9',
  },
  detailsRowEven: {
    backgroundColor: '#ffffff',
  },
  detailsRowTitle: {
    fontWeight: 'bold',
  },
  detailsRowValue: {
    marginLeft: 10,
  },
  buttonList: {
	  
  },
})

