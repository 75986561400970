import React, { useState, useEffect } from 'react'
import { Image, Linking, StyleSheet, TouchableOpacity, View, Text, Pressable } from 'react-native'
import Styles from '../../constants/Styles'
import Colors from '../../constants/Colors'
import menu from '../Hamburger_icon_white.svg';
import logo from '../logo.png';
import Para from './Para'
import close_button from '../ico_cross.png'
import menu_close from '../ico_menu_close.png'


function NavigationBarIcons(props) {
	const [navOpen, updateNavOpen] = useState(false)
	
	const helpLink = 'https://www.manheim.co.uk/campaigns/surecheck-faqs'

	function toggleMenu()
	{
		updateNavOpen(!navOpen);
	}

	function clearData()
	{
		updateNavOpen(false);
		props.clearData();
	}

  return (
    <View style={styles.navigationBarIcons}>
      <Image style={styles.navigationBarLogo} source={logo}></Image>
	  <View style={styles.navigationBarIcons}>
		  <Para color='#ffffff' bold={false} fontSize={26} marginTop={40} style={styles.navigationBarHeader}>SURECHECK PORTAL</Para>
		  <Para color='#ffffff' fontSize={24}> | </Para>
		  <View>
		  <TouchableOpacity onPress={() => toggleMenu()}>
			  <Image style={styles.navigationBarIconSmall} source={menu}></Image>
		  </TouchableOpacity>
			  { navOpen && 
					<View style={styles.menuWrapper}>
						<View style={styles.menucloseIconSmall}>
							<Pressable onPress={() => toggleMenu()} style={styles.cancelmenu}><Image style={styles.menucloseIconSmall} source={menu_close}></Image></Pressable>
						</View>
						<View style={styles.menuItems}>
							<Pressable style={styles.menuItem} onPress={() => Linking.openURL(`${helpLink}`)}>Open FAQs</Pressable>
							<Pressable style={[styles.menuItem, styles.menuItemClear]} onPress={() => clearData()}>Clear Current Data</Pressable>
							<Pressable style={styles.menuItem} onPress={() => toggleMenu()}>Close Menu <Image style={styles.closeIconSmall} source={close_button}></Image></Pressable>
						</View>
					</View>
				}
		  </View>
	  </View>
	</View>
  )
}

const styles = StyleSheet.create({
  navigationBarIcons: {
	marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.standardPadding,
  },
  navigationBarLogo: {
    height: 55,
    width: 151,
	marginTop: 13
  },
  navigationBarIconSmall: {
	marginTop: 10,
    height: 30,
    width: 38
  },
  closeIconSmall: {
	position: 'relative',
    height: 20,
    width: 20,
	marginLeft: 20,
  },
  menucloseIconSmall: {
    height: 50,
    width: 50,
  },
  navigationBarHeader: {
    color: '#ffffff',
  },
  menuWrapper: {
	  flexDirection: 'column',
	  position: 'fixed',
	  top: 0,
	  right: 0,
	  backgroundColor: '#ffffff',
	  width: 300,
	  height: '100%'
  },
  menuItems: {
	  padding: 15,
	  paddingLeft: 70,
	  position: 'fixed',
	  top: 0,
  },
  menuItem: {
	  flexDirection: 'row',
	  color: Colors.primaryPurple,
	  fontSize: 20,
	  borderBottomStyle: 'solid',
	  borderBottomWidth: 1,
	  borderColor: '#f1f1f1',
	  paddingTop: 10,
	  paddingBottom: 10,
  },
  menuItemClear: {
	  color: '#FF0000',
  },
  cancelmenu: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: 0,
    left: 0,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
    border: 'none',
  }
})

export default NavigationBarIcons
