import { View, StyleSheet, Text, TextInput, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

function Confirm(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Confirm and Submit
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Para>Thank you for providing us the information, Please select SUBMIT REQUEST, we can then start the assessment of your request.</Para>
	  <Para>If you have another fault to report, please select ADD ANOTHER FAULT. </Para>
      <Bottom>
        <VerticalSpace height={45}/>
		<DefaultButton greenbg={true} text="Submit Request" onClick={props.onSubmit}/>
        <VerticalSpace height={15}/>
			{props.categories.length < 6 ?
			<DefaultButton text="Add Another Fault" onClick={props.onAddAnother}/> : null}
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default Confirm;




