import React from 'react'
import { StyleSheet, View, TextInput } from 'react-native'
//import { TextInput } from 'react-native-gesture-handler'
import Para from './Para'

type LabeledTextFieldProps = {
  label: string,
  onChange: (text: string) => void,
  placeholder?: string,
  value: string,
  regstyle: any
}

const LabeledTextField = ({ label, onChange, placeholder = '', value, regstyle }: LabeledTextFieldProps) => {
  if (regstyle == true)
  {
	  return (
		<View>
		  <Para color={'#0d4489'} fontSize={18}>{label}</Para>
		  <TextInput value={value} placeholder={placeholder} style={styles.regTextInput} onChangeText={onChange}/>
		</View>
	  )	  
  }
  else
  {
	  return (
		<View>
		  <Para color={'#0d4489'} fontSize={18}>{label}</Para>
		  <TextInput value={value} placeholder={placeholder} style={styles.textInput} onChangeText={onChange}/>
		</View>
	  )
  }
}

export default LabeledTextField

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 35,
    paddingHorizontal: 4
  },
  regTextInput: {
    borderWidth: 1,
	borderColor: '#333',
	color: '#333',
	backgroundColor: '#fdc42d',
    fontSize: 16,
    height: 35,
    padding: 14,
	flex: 1,
    justifyContent: "center",
    alignItems: "center",
	textTransform: 'uppercase',
  }
})
