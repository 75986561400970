import React, { Component } from 'react';
import SearchVehicle from '../../Vehicle/SearchVehicle';
import VehicleNotFound from '../../Apps/NewRequest/VehicleNotFound';
import NoInspectionReport from './NoInspectionReport';
import YourVehicle from './YourVehicle';
import API from '../../API';

class InspectionReport extends Component {
  constructor(props) {
    super();

    this.state = {
      stage: 'searchVehicle',
    };

    this.apiFail = this.apiFail.bind(this);
    this.apiDone = this.apiDone.bind(this);
    this.onDownloadReport = this.onDownloadReport.bind(this);
	
    this.onSubmitSearchVehicle = this.onSubmitSearchVehicle.bind(this);
  };
  
  
  apiFail(xhr) {
	this.setState({stage: 'uploadError'});
  }
  
  apiDone() {
		this.setState({stage: 'submitComplete'});
  }

  onSubmitSearchVehicle (vrn) {
	  let theApp = this;
	  let api = new API();
	  this.setState({vrn: vrn});
	  	  console.log(this.state)


	api.policies(vrn, function done(response) {
		console.log(response.policy.InspectionReportUrl);
	  if (response.policy.Id == 0)
	  {
		  console.log("1");
		  theApp.setState({stage: 'noInspectionReport'});
	  }
	  else if (response.policy.InspectionReportUrl == null)
	  {
		  console.log("1");
		theApp.setState({stage: 'noInspectionReport'});
	  }
	  else if (response.policy) {
		  console.log("1");
		  theApp.setState({stage: 'yourVehicle', policy: response.policy});
	  }
	}, function notFound() {
		  console.log("1");
		theApp.setState({stage: 'noInspectionReport'});
	}, function fail(xhr) {
		  console.log("1");
		theApp.setState({stage: 'noInspectionReport'});
	});
  }
  
  onDownloadReport() {
		const pdfWindow = window.open(this.state.policy.InspectionReportUrl, '_blank');
  }

  render() {
	  console.log(this.state.stage);
    if (this.state.stage === 'yourVehicle') {
      return(
        <YourVehicle policy={this.state.policy} onDownloadReport={this.onDownloadReport} />
      );
    }
	else if (this.state.stage === 'vehicleNotFound') {
      return(
        <VehicleNotFound vrn={this.state.vrn} onSubmit={this.onNotFoundSubmit} />
      );
    }
	else if (this.state.stage === 'noInspectionReport') {
      return(
        <NoInspectionReport goBack={this.props.goBack} />
      );
    }
	else {
      return(
        <SearchVehicle onSearch={this.onSubmitSearchVehicle}/>
      );
    }
  }
}

export default InspectionReport;
