import React, { FunctionComponent, ReactNode } from 'react'
import { Text, Platform } from 'react-native'

type ParaProps = {
  bold?: boolean
  centerAlign?: boolean
  children?: ReactNode
  color?: string
  fontSize?: number
  display?: string
}

let displayVal = 'block'
if (Platform.OS == "android")
{
	displayVal = 'flex'
}

const Para: FunctionComponent<ParaProps> = (props: ParaProps) => {
  return <Text style={{
    color: props.color || '#227cb7',
    fontSize: props.fontSize || 16,
    fontWeight: props.bold ? 'bold' : 'normal',
    marginBottom: 6,
    marginTop: 6,
    textAlign: props.centerAlign ? 'center' : 'left',
	display: props.display || displayVal
  }}>
    {props.children}
  </Text>
}

export default Para
