import { View, StyleSheet, Text, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'


function Repairer(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);


  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
	  <SmallHeading>
	    Are you a repairer?
	  </SmallHeading>
	  <VerticalSpace height={45}/>
      <Bottom>
        <VerticalSpace height={45}/>
		<View>
			<DefaultButton greenbg={true} text="Yes" onClick={props.onRepairerYes}/>
			<VerticalSpace height={15}/>
			<DefaultButton text="No" onClick={props.onRepairerNo}/>
		</View>
      </Bottom>
    </View>
    </Animated.View>
  );
}

export default Repairer;


