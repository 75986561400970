import { View, StyleSheet, Text, TextInput, Dimensions, Image, Platform, Animated } from 'react-native';
import React, { useState, useEffect, useRef } from 'react'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import LabeledTextField from '../../components/LabeledTextField'
import SmallHeading from '../../components/SmallHeading';
import Para from '../../components/Para';
import Styles from '../../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import * as Progress from 'react-native-progress';
import Colors from '../../../constants/Colors'

function NotFoundSubmitComplete(props) {
	const fadeAnim = useRef(new Animated.Value(0)).current; 
	
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);
  
  return(
  <Animated.View style={{opacity: fadeAnim }}>
    <View style={Styles.container}>
		<View style={styles.centerdiv}>
		  <SmallHeading>
		  Successful Request <View style={styles.greenTick}>&#x2714;</View>
		  </SmallHeading>
		  <VerticalSpace height={45}/>
		  <Progress.Bar progress={100} width={200} />
		  <VerticalSpace height={45}/>
		  <Para>Your request has been successfully submitted and we will be in touch.</Para>
		</View>
    </View>
  </Animated.View>
  );
}

export default NotFoundSubmitComplete;



const styles = StyleSheet.create({
  centerdiv: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  greenTick: {
	  color: Colors.green,
	  fontWeight: 'bold',
  },
})

